import React, { FC, useEffect, useState } from "react";
import call from "../assets/images/call.svg";
import w1 from "../assets/images/w1.svg";
import w2 from "../assets/images/w2.svg";
import w3 from "../assets/images/w3.svg";
import w4 from "../assets/images/w4.svg";
import w5 from "../assets/images/w5.svg";
import PartnerInfo from "../components/partner-info";
import { Button } from "../components/ui";
import PageMainHeader from "../components/commons/page-main-header";
import PageHeader from "../components/commons/page-header";
import useAxiosClient from "../hooks/useAxiosClient";
import { TCrop, TSupplyDemandReq } from "../types";
import { useDispatch } from "react-redux";
import { updateCropData } from "../reducers/tradeSlice";
import { getCurrencyNumberWithOutSymbol } from "../helpers/get-currency-number";
import moment from "moment";
import { matchPath, useLocation } from "react-router-dom";
import Modal from "../components/ui/modal";
import ModalHeader from "../components/ui/modal/modal-header";
import CropImageGallery from "./crop-image-gallery";
import NoCropView from "./no-crop-view";
const PostDetails: FC<{}> = ({}) => {
  const { handleGetCall } = useAxiosClient();
  const dispatch = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (matchPath("/post-view/:pId", location.pathname)) {
      let id = location.pathname.split("/")[2];
      //   setTitle(`Trades | Supply Post ID: ${id}`);
      getSupplyDetail(id);
    }
  }, [location.pathname]);
  //   useEffect(() => {
  //     getSupplyDetail();
  //   }, []);
  const [supplyDemandDetails, setSupplyDemandDetails] =
    useState<TSupplyDemandReq | null>();
  const [imageGalleryOpen, setImageGalleryOpen] = useState(false);
  const [cropName, setCropname] = useState("");
  const getSupplyDetail = (id: any) => {
    handleGetCall<TSupplyDemandReq>(
      `admin/demand-supply/supply/supplyId/${id}`
    ).then(({ data }) => {
      if (data) {
        let cropsData: TCrop;

        if (data.cropName) {
          cropsData = {
            baseName: "",
            cropId: data?.cropId || 0,
            cropName: data?.cropName || "",
            imageUrl: data?.cropUrl || "",
            type: "",
            units: "",
            varietyName: "",
            qtyUnit: "",
            rateType: "",
            qty: data?.qty || "",
            weight: "",
            freightAmt: "",
            status: 1,
            tradeId: 0,
            weightUnit: "",
            id: 0,
            add_wastage: 0,
            destWeight: 0,
            destRate: "",
            rate: data?.expRate || "",
            unitType: "",
          };
          dispatch(
            updateCropData({
              //   selectedParty: selectedParty,
              selectedCrop: cropsData,
            })
          );
        } else {
          dispatch(
            updateCropData({
              //  selectedParty: selectedParty,
              selectedCrop: null,
            })
          );
        }

        setSupplyDemandDetails({
          ...data,
          quality: data?.quality ? data?.quality.toString().toUpperCase() : "",
          traderNotifns: data?.traderNotifns ? data?.traderNotifns : [],
          //   totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
        });
        // dispatch(
        //   updateSupplyDetails({
        //     supplyDetailsById: {
        //       ...data,
        //       quality: data?.quality
        //         ? data?.quality.toString().toUpperCase()
        //         : "",
        //       traderNotifns: data?.traderNotifns ? data?.traderNotifns : [],
        //       //   totalBillsAmount: data?.buyBillAmt + data?.sellBillAmt,
        //     },
        //   })
        // );
      } else {
        setSupplyDemandDetails(null);
      }
    });
  };
  const [number, setNumber] = useState("");
  useEffect(() => {
    if (supplyDemandDetails != null && supplyDemandDetails?.mlcMobile) {
      setNumber(supplyDemandDetails?.mlcMobile.replace(/\s+/g, ""));
    }
  }, [supplyDemandDetails?.mlcMobile]);
  return (
    <>
      <PageMainHeader />
      <PageHeader
        // showBackButton={true}
        showTitle={true}
        title={`Supply Post`}
        topVal="3.7rem"
      />

      <div className="py-4 px-4" style={{ backgroundColor: "#EFF3EF" }}>
        <div className="border br-10 bg-white mb-4">
          <div className="py-4 px-4 border-b">
            <PartnerInfo
              profilePic={supplyDemandDetails?.profilePic || ""}
              name={supplyDemandDetails?.traderName || ""}
              shortName={""}
              partnerType={supplyDemandDetails?.tradeType}
              partnerId={supplyDemandDetails?.traderId}
              partnerMobile={supplyDemandDetails?.mobile || ""}
              partyName={supplyDemandDetails?.addressLine}
            />
          </div>
          <div className="">
            <div className="flex items-center gap-x-3 py-4 border-b px-4">
              <div>
                <div
                  className="relative cursor-pointer"
                  onClick={(e) => {
                    //   setImageGalleryOpen(true);
                    //   dispatch(
                    //     updateProposalCropImgs({
                    //       proposalCropImages: supply.imgs,
                    //     })
                    //   );
                    //   setCropname(supply?.cropName);
                    //   e.stopPropagation();
                  }}
                >
                  {
                    supplyDemandDetails?.imgs &&
                    supplyDemandDetails.imgs.length > 0 ? (
                      // req.imgs.map((img, i) => (
                      <div
                        className="relative cursor-pointer"
                        onClick={(e) => {
                          setImageGalleryOpen(true);
                          // dispatch(
                          //   updateProposalCropImgs({
                          //     proposalCropImages: supply.imgs,
                          //   })
                          // );
                          setCropname(supplyDemandDetails?.cropName);
                          e.stopPropagation();
                        }}
                      >
                        <img
                          src={supplyDemandDetails.imgs[0].url}
                          alt=""
                          className="br-10 "
                          style={{ width: "120px", height: "70px" }}
                        />
                      </div>
                    ) : (
                      <NoCropView />
                    )
                    // ))
                  }
                  {/* <img
                    src={supplyDemandDetails?.cropUrl}
                    alt=""
                    className="br-10 "
                    style={{ width: "120px", height: "70px" }}
                  /> */}
                </div>
              </div>
              <div className="flex flex-col">
                <div className="flex items-center gap-x-3">
                  {/* <div>
                    <img
                      src={supplyDemandDetails?.cropUrl}
                      alt=""
                      className="br-100 w-8 h-8 "
                    />
                  </div> */}
                  <div className="flex flex-col">
                    <span className="text-sm font-bold">
                      {supplyDemandDetails?.cropName}
                    </span>
                    <span className="text-xs font-medium">
                      {supplyDemandDetails?.quality
                        ? supplyDemandDetails?.quality + " Quality"
                        : "-"}
                    </span>

                    <span className="text-xs">
                      Post ID :{" "}
                      <span className="color-blue">
                        {supplyDemandDetails?.id}
                      </span>{" "}
                      | {supplyDemandDetails?.timeAgo}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex gap-2 border-b py-4 px-4">
              <div className="flex-1 flex flex-col">
                <span className="text-sm">Quantity</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {getCurrencyNumberWithOutSymbol(supplyDemandDetails?.qty) +
                    " " || "-"}
                  {supplyDemandDetails?.unitType}
                </span>
              </div>
              <div className="flex-1 flex flex-col">
                <span className="text-sm">Expected Rate</span>
                <span className="text-sm font-semibold">
                  {" "}
                  Rs.{supplyDemandDetails?.expRate} Per KG
                </span>
              </div>
            </div>
            <div className="flex gap-2 border-b py-4 px-4">
              <div className="flex-1 flex flex-col">
                <span className="text-sm">Availability Date</span>
                <span className="text-sm font-semibold">
                  {" "}
                  {moment(supplyDemandDetails?.avlDate).format("DD-MMM-YYYY") ||
                    "-"}
                </span>
              </div>
            </div>
          </div>
          <div className="py-4 px-4">
            <p className="text-sm font-bold pb-2">Admin Contact Info</p>
            <div className="flex gap-2">
              <div className="flex-1 flex gap-1">
                <img src={call} alt="image" />
                <div className="flex flex-col">
                  <span className="text-sm">Mobile number</span>
                  <span className="text-sm font-semibold">
                    {supplyDemandDetails?.mlcMobile || "-"}
                  </span>
                </div>
              </div>
              <div className="flex-1">
                {supplyDemandDetails?.mlcMobile ? (
                  <a href={`https://wa.me/${number}`} target="_blank">
                    <Button variant="contained">Chat with us</Button>
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        {/* <div className="border br-10 px-4 py-4 flex flex-col mb-4 bg-white">
          <span className="text-base font-bold pb-2">Get the App Now!</span>
          <span className="text-sm pb-4">
            Download the application today to get access all features!
          </span>
          <Button variant="contained" className="w-full">
            Download Now
          </Button>
        </div> */}
        <div className="flex flex-col">
          <p className="font-bold text-base mb-2">How it works?</p>
          <div className="">
            {/* <div className="border-l" style={{ height: "100%" }}></div> */}
            <div className="border py-5 px-4 flex flex-col gap-8 bg-white">
              <div className="flex gap-4">
                <img src={w1} />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-bold">
                    Create Trade Business Account
                  </span>
                  <span className="text-sm">
                    Access MANDI module and create trade business account in
                    single step.
                  </span>
                </div>
              </div>
              <div className="flex gap-4">
                <img src={w2} />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-bold">
                    Post Demand / Supply Requirements
                  </span>
                  <span className="text-sm">
                    Post your demand / supply requirements with detailed
                    specifications and sample commodity images.
                  </span>
                </div>
              </div>
              <div className="flex gap-4">
                <img src={w3} />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-bold">
                    Review & Accept Proposals
                  </span>
                  <span className="text-sm">
                    Review all proposals terms and prices and directly interact
                    with partners through in-app chat or calls to decide on a
                    desired proposal and accept it.
                  </span>
                </div>
              </div>
              <div className="flex gap-4">
                <img src={w4} />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-bold">
                    Track your Orders Status
                  </span>
                  <span className="text-sm">
                    Track your order status and receive real-time updates of
                    auction, crop images, order preparation, transportation &
                    arrival details.
                  </span>
                </div>
              </div>
              <div className="flex gap-4">
                <img src={w5} />
                <div className="flex flex-col gap-1">
                  <span className="text-sm font-bold">
                    Make / Receive Payment
                  </span>
                  <span className="text-sm">
                    Review orders closures and make / receive on-time payments.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {imageGalleryOpen && (
        <>
          <Modal
            open={imageGalleryOpen}
            onClose={() => {
              setImageGalleryOpen(false);
            }}
            width="40vw"
          >
            <>
              <ModalHeader title={cropName} content={<></>} />
              <div className="py-3 px-6">
                <CropImageGallery
                  onModalClose={setImageGalleryOpen}
                  proposalCropImages={supplyDemandDetails?.imgs}
                />
              </div>
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export default PostDetails;
