import { FC, ReactNode } from "react";
import { useSelector } from "react-redux";
import Login from "../../pages/login";
import { LOGGEDIN } from "../../constants/storage-keys";
import { TRootState } from "../../store";
import PostDetails from "../../pages/post-details";
import {
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
const AuthLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { loggedin } = useSelector((state: TRootState) => ({
    loggedin: state.auth.loggedin,
  }));
  const pathname = window.location.pathname;

  const match = pathname.match(/\/pid-(\d+)/);
  if (loggedin === false) {
    if (match) {
      // let id = param.replace("pid-", "");
      const pid = match[1];
      // return <PostDetails />;
      navigate(`/post-view/${pid}?onBack=home`);
    } else if (matchPath("/post-view/:pid", location.pathname)) {
      return <PostDetails />;
    } else {
      return <Login />;
    }
  }
  return <>{children}</>;
};
export default AuthLayout;
